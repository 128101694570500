import Step from "../../components/step/Step";
import {Box, Button, Heading, Image, SimpleGrid} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import Chart from './Chart.svg';

function Step3() {
    let navigate = useNavigate();

    return (
        <Step currentStep={3}
              backLink={'/step2'}>

            <Heading>
                Heading
            </Heading>

            <Image width={'100%'} src={Chart} />

            <SimpleGrid columns={2} spacing={10} pt={2}>
                <Box textAlign={'left'}>
                    1 Monat
                </Box>
                <Box textAlign={'right'}>
                    3 Monat
                </Box>
            </SimpleGrid>

            <Button bg="brand"
                    color={'white'}
                    size="md"
                    mt={5} w={'100%'}
                    onClick={() => {
                        navigate('../step4', {replace: true})
                    }}
                    type="submit">
                <FormattedMessage id="form.button.next" />
            </Button>
        </Step>
    );
}

export default Step3;
