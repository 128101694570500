import * as React from "react";
import {ChakraProvider} from "@chakra-ui/react"
import {
    MemoryRouter,
    Route,
    Routes
} from "react-router-dom";
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import {IntlProvider} from "react-intl";
import German from './lang/de.json';
import Dutch from './lang/nl.json';
import French from './lang/fr.json';
import English from './lang/en.json';
import Polish from './lang/pl.json';

import ChakraTheme from "./templates/Chakra";
import ScapsTemplate from "./templates/Scaps";

import Home from "./pages/home/Home";
import Step1 from "./pages/step1/Step1";
import Step2 from "./pages/step2/Step2";
import Step3 from "./pages/step3/Step3";
import Step4 from "./pages/step4/Step4";
import Step5 from "./pages/step5/Step5";
import Step6 from "./pages/step6/Step6";
import Step7 from "./pages/step7/Step7";
import Step8 from "./pages/step8/Step8";
import Step9 from "./pages/step9/Step9";
import Step10 from "./pages/step10/Step10";
import Step11 from "./pages/step11/Step11";
import Step12 from "./pages/step12/Step12";
import Loader from "./pages/loader/Loader";
import Complete from "./pages/complete/Complete";

import success1 from "./assets/step4/success4.jpg";
import success2 from "./assets/step4/success1.jpg";
import success3 from "./assets/step9/success1.jpg";
import useImagePreloader from "./hooks/UseImagePreloader";

function App() {
   const currentUrl = window.location.toString();

    const preloadSrcList = [
        success1,
        success2,
        success3
    ]

    useImagePreloader(preloadSrcList)

    let messages = German;
    let locale = 'de';
    moment.locale('de');

    switch (true) {
        case (currentUrl.search('/nl') !== -1):
            messages = Dutch;
            locale = 'nl';
            moment.locale('nl');
            break;

        case (currentUrl.search('/fr') !== -1):
            messages = French;
            locale = 'fr';
            moment.locale('fr');
            break;

        case (currentUrl.search('/uk') !== -1):
            messages = English;
            locale = 'en';
            moment.locale('en');
            break;

        case (currentUrl.search('/pl') !== -1):
            messages = Polish;
            locale = 'pl';
            moment.locale('pl');
            break;

        case (currentUrl.search('/ie') !== -1):
            messages = English;
            messages.outgoingUrl = 'https://s-caps.com/ie/buy-now.html';
            locale = 'en';
            moment.locale('en');
            break;

        case (currentUrl.search('/befr') !== -1):
            messages = French;
            messages.outgoingUrl = 'https://s-caps.com/befr/acheter-maintenant.html';
            locale = 'fr';
            moment.locale('fr');
            break;

        case (currentUrl.search('/benl') !== -1):
            messages = Dutch;
            messages.outgoingUrl = 'https://s-caps.com/benl/nu-kopen.html';
            locale = 'nl';
            moment.locale('nl');
            break;

        case (currentUrl.search('/lude') !== -1):
            messages.outgoingUrl = 'https://s-caps.com/lude/jetzt-kaufen.html';
            break;

        case (currentUrl.search('/lufr') !== -1):
            messages = French;
            messages.outgoingUrl = 'https://s-caps.com/lufr/acheter-maintenant.html';
            locale = 'fr';
            moment.locale('fr');
            break;

        case (currentUrl.search('/at') !== -1):
            messages.outgoingUrl = 'https://s-caps.com/at/jetzt-kaufen.html';
            messages['step5.headline'] = 'Anna aus Wien';
            messages['step10.headline'] = 'Svenja aus Graz';
            break;

        case (currentUrl.search('/ch') !== -1):
            messages.outgoingUrl = 'https://s-caps.com/ch/jetzt-kaufen.html';
            messages['step5.headline'] = 'Anna aus Basel';
            messages['step10.headline'] = 'Svenja aus Zürich';
            locale = 'de-ch';
            break;

        default:
    }

    document.title = messages.title;

    return (
        <MemoryRouter>
            <ChakraProvider theme={ChakraTheme}>
                <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                    <ScapsTemplate>
                        <Routes>
                            <Route path='/' element={
                                <Home/>
                            }/>
                            <Route path='/step1' element={
                                <Step1/>
                            }/>
                            <Route path='/step2' element={
                                <Step2/>
                            }/>
                            <Route path='/step3' element={
                                <Step3/>
                            }/>
                            <Route path='/step4' element={
                                <Step4/>
                            }/>
                            <Route path='/step5' element={
                                <Step5/>
                            }/>
                            <Route path='/step6' element={
                                <Step6/>
                            }/>
                            <Route path='/step7' element={
                                <Step7/>
                            }/>
                            <Route path='/step8' element={
                                <Step8/>
                            }/>
                            <Route path='/step9' element={
                                <Step9/>
                            }/>
                            <Route path='/step10' element={
                                <Step10/>
                            }/>
                            <Route path='/step11' element={
                                <Step11/>
                            }/>
                            <Route path='/step12' element={
                                <Step12/>
                            }/>
                            <Route path='/loader' element={
                                <Loader/>
                            }/>
                            <Route path='/complete' element={
                                <Complete/>
                            }/>
                        </Routes>
                    </ScapsTemplate>
                </IntlProvider>
            </ChakraProvider>
        </MemoryRouter>
    );
}

export default App;
