import Step from "../../components/step/Step";
import {Box, SimpleGrid, Text, useRadioGroup} from "@chakra-ui/react";
import RadioCard from "../../components/radio-card/RadioCard";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";

function Step7() {
    let navigate = useNavigate();
    const intl = useIntl();

    const {getRootProps, getRadioProps} = useRadioGroup({
        name: 'q7',
        onChange: () => {
            navigate('../step8', {replace: true});
        }
    });

    const options = [
        {
            'id': 'a1',
            'text': intl.formatMessage({id: 'step7.option1'})
        },
        {
            'id': 'a2',
            'text': intl.formatMessage({id: 'step7.option2'})
        },
        {
            'id': 'a3',
            'text': intl.formatMessage({id: 'step7.option3'})
        },
        {
            'id': 'a4',
            'text': intl.formatMessage({id: 'step7.option4'})
        }
    ];

    const group = getRootProps()

    return (
        <Step heading={intl.formatMessage({id: 'step7.headline'})}
              currentStep={5}
              backLink={'/step6'}>

            <SimpleGrid mt={5} spacing={2} columns={1} {...group}>
                {options.map((value) => {
                    const radio = getRadioProps({'value': value.id})
                    return (
                        <RadioCard key={value.id} {...radio}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Text fontSize={'lg'} fontWeight={'bold'}>{value.text}</Text>
                            </Box>
                        </RadioCard>
                    )
                })}
            </SimpleGrid>
        </Step>
    );
}

export default Step7;
