import "./Scaps.scss";
import '@fontsource/inter/700.css';
import '@fontsource/inter/400.css'
import Logo from './Logo.svg';
import {Box, Image} from "@chakra-ui/react";

function ScapsTemplate(props) {
    return (
        <div className={"page scaps"}>
            <Box pt={[1,5]} pb={[0,4]} textAlign={'center'}>
                <Image src={Logo} height={[10,20]} display={'inline-block'} />
            </Box>

            {props.children}
        </div>
    );
}

export default ScapsTemplate;
